/* src/app/common/breadcrumbs/breadcrumbs.component.scss */
.holder {
  background-color: #f3f2f1;
  border-top: 1px #f3f2f1 solid;
  margin: 0 auto;
  width: 100%;
}
.breadcrumbs {
  font-size: 18px;
  line-height: 72px;
  margin: 0 auto;
  max-width: 1170px;
  padding-left: 60px;
}
.link {
  color: #626262 !important;
  font-weight: 600;
}
.last {
  color: #626262 !important;
  font-size: 16px;
}
.link:hover {
  cursor: pointer;
}
@media screen and (max-width: 766px) {
  .breadcrumbs {
    display: none;
  }
}
/*# sourceMappingURL=breadcrumbs.component-4R5FGOXG.css.map */
